
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New Expenses </h2>
    <mat-tab-group>
      <mat-tab label="supplier">
        <form [formGroup]="expensesForm">
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-4">
              <label for="companyName">Company Name<span class="requried-field">*</span></label>
              <input
                type="text"
               readonly
                placeholder="Enter companyName"
                class="form-control"
                formControlName="companyName"
                [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
              />
              <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
                <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
              </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="supplierName">Supplier Name<span class="requried-field">*</span></label>
              <ng-autocomplete
            [data]="supplierName"
            [searchKeyword]="keyword5"
            placeholder="Enter the supplier Name"
            (selected)='selectEvent5($event)'
            (inputChanged)='onChangeSearch5($event)'
            (inputFocused)='onFocused5($event)'
            historyIdentifier="supplierName"
            [itemTemplate]="itemTemplate1"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate1 let-item>
            <a [innerHTML]="item.supplierName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
              <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
              </div>
          </div>
     
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="date">Date<span class="requried-field">*</span></label>
                <input
                
                 readonly
                  placeholder="Enter date"
                  class="form-control"
                  formControlName="date"
                  [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                />
                <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                  <div *ngIf="f['date'].errors['required'] ">Date is required</div>
                </div>
            </div>
            <!-- <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="toDate">To Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="toDate"
                  placeholder="Enter toDate"
                  class="form-control"
                  formControlName="toDate"
                  [ngClass]="{ 'is-invalid': submitted && f['toDate'].errors }"
                />
                <div *ngIf="submitted && f['toDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['toDate'].errors['required']">To Date is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="expensesType">Expenses Type<span class="requried-field">*</span></label>
                <select
            id="expensesType"
            name="expensesType"
            class="form-control"
            formControlName="expensesType"
            [ngClass]="{ 'is-invalid': submitted && f['expensesType'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of expensesType"
              [value]="data"
              >{{ data}}
            </option>
          </select>
                <div *ngIf="submitted && f['expensesType'].errors" class="invalid-feedback">
                  <div *ngIf="f['expensesType'].errors['required']">To Date is required</div>
                </div>
            </div> -->
            <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="paymentMode">Payment Mode<span class="requried-field">*</span></label>
                <select
                          id="paymentMode"
                          name="paymentMode"
                          class="form-control"
                          formControlName="paymentMode"
                          [(ngModel)]="ispayment"
                          (ngModelChange)="payment($event)"
    
                          [ngClass]="{ 'is-invalid': submitted && f['paymentMode'].errors }"
                        >
                        <option value="" selected disabled>--Please select--</option>
                          <option
                            *ngFor="let data of paymentMode"
                            [value]="data"
                            >{{ data }}
                          </option>
                        </select>
                <div *ngIf="submitted && f['paymentMode'].errors" class="invalid-feedback">
                  <div *ngIf="f['paymentMode'].errors['required']">Payment Mode is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="chequeNo">Cheque No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="chequeNo"
                  placeholder="Enter Cheque No"
                  class="form-control"
                  formControlName="chequeNo"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['chequeNo'].errors }"
                />
                <div *ngIf="submitted && f['chequeNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['chequeNo'].errors['required']">Cheque No is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="accountNo">Account No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="accountNo"
                  placeholder="Enter account No"
                  class="form-control"
                  formControlName="accountNo"
                  [attr.disabled]="ischeque? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
                />
                <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="withdrawDate">Withdraw Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="withdrawDate"
                  class="form-control"
                  formControlName="withdrawDate"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['withdrawDate'].errors }"
                />
                <div *ngIf="submitted && f['withdrawDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['withdrawDate'].errors['required']">Withdraw Date is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="transactionNo">Transaction No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="transactionNo"
                  placeholder="Enter Transaction No"
                  class="form-control"
                  formControlName="transactionNo"
                  [attr.disabled]="isOnline ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['transactionNo'].errors }"
                />
                <div *ngIf="submitted && f['transactionNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['transactionNo'].errors['required']">Transaction No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="ddNo">DD NO <span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="ddNo"
                  placeholder="Enter ddNo"
                  class="form-control"
                  formControlName="ddNo"
                  [attr.disabled]="isDD ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['ddNo'].errors }"
                />
                <div *ngIf="submitted && f['ddNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['ddNo'].errors['required']">DD No is required</div>
                </div>
              </div>
    
              
              
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="receiptNo">Receipt No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="receiptNo"
                  placeholder="Enter Receipt No"
                  class="form-control"
                  [attr.disabled]="iscash ? true : null"
                  formControlName="receiptNo"
                  [ngClass]="{ 'is-invalid': submitted && f['receiptNo'].errors }"
                />
                <div *ngIf="submitted && f['receiptNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['receiptNo'].errors['required']">Receipt No is required</div>
                </div>
              </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="projectName">Project Name<span class="requried-field">*</span></label>
                <ng-autocomplete
              [data]="projectName"
              [searchKeyword]="keyword1"
              placeholder="Enter the Category Name"
              (selected)='selectEvent1($event)'
              (inputChanged)='onChangeSearch1($event)'
              (inputFocused)='onFocused1($event)'
              historyIdentifier="projectName"
              [itemTemplate]="itemTemplate1"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
          
            <ng-template #itemTemplate1 let-item>
              <a [innerHTML]="item.projectName"></a>
            </ng-template>
          
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
                <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                  <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="siteNo">SiteNo<span class="requried-field">*</span></label>
                <ng-autocomplete
                [data]="siteNo"
                [searchKeyword]="keyword2"
                placeholder="Enter the Category Name"
                (selected)='selectEvent2($event)'
                (inputChanged)='onChangeSearch2($event)'
                (inputFocused)='onFocused2($event)'
                historyIdentifier="siteNo"
                [itemTemplate]="itemTemplate2"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            
              <ng-template #itemTemplate2 let-item>
                <a [innerHTML]="item.siteNo"></a>
              </ng-template>
            
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
                <div *ngIf="submitted && f['siteNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['siteNo'].errors['required']">Site No is required</div>
                </div>
            </div>
            </div>
           
    
          
      
          <div class="formbutton">
    
         
            <button type="submit" class="btn btn-primary" (click)="onSubmit(expensesForm.value)">Submit</button>
            <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Labour">
        <form [formGroup]="expensesForm">
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-4">
              <label for="companyName">Company Name<span class="requried-field">*</span></label>
              <input
                type="text"
               readonly
                placeholder="Enter companyName"
                class="form-control"
                formControlName="companyName"
                [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
              />
              <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
                <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
              </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="labourName">Labour Name<span class="requried-field">*</span></label>
              <ng-autocomplete
            [data]="labourName"
            [searchKeyword]="keyword6"
            placeholder="Enter the labour Name"
            (selected)='selectEvent6($event)'
            (inputChanged)='onChangeSearch6($event)'
            (inputFocused)='onFocused6($event)'
            historyIdentifier="labourName"
            [itemTemplate]="itemTemplate6"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate6 let-item>
            <a [innerHTML]="item.labourName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
             
          </div>
     
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="date">Date<span class="requried-field">*</span></label>
                <input
                
                 readonly
                  placeholder="Enter date"
                  class="form-control"
                  formControlName="date"
                  [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                />
                <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                  <div *ngIf="f['date'].errors['required'] ">Date is required</div>
                </div>
            </div>
            <!-- <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="toDate">To Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="toDate"
                  placeholder="Enter toDate"
                  class="form-control"
                  formControlName="toDate"
                  [ngClass]="{ 'is-invalid': submitted && f['toDate'].errors }"
                />
                <div *ngIf="submitted && f['toDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['toDate'].errors['required']">To Date is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="expensesType">Expenses Type<span class="requried-field">*</span></label>
                <select
            id="expensesType"
            name="expensesType"
            class="form-control"
            formControlName="expensesType"
            [ngClass]="{ 'is-invalid': submitted && f['expensesType'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of expensesType"
              [value]="data"
              >{{ data}}
            </option>
          </select>
                <div *ngIf="submitted && f['expensesType'].errors" class="invalid-feedback">
                  <div *ngIf="f['expensesType'].errors['required']">To Date is required</div>
                </div>
            </div> -->
            <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="paymentMode">Payment Mode<span class="requried-field">*</span></label>
                <select
                          id="paymentMode"
                          name="paymentMode"
                          class="form-control"
                          formControlName="paymentMode"
                          [(ngModel)]="ispayment"
                          (ngModelChange)="payment($event)"
    
                          [ngClass]="{ 'is-invalid': submitted && f['paymentMode'].errors }"
                        >
                        <option value="" selected disabled>--Please select--</option>
                          <option
                            *ngFor="let data of paymentMode"
                            [value]="data"
                            >{{ data }}
                          </option>
                        </select>
                <div *ngIf="submitted && f['paymentMode'].errors" class="invalid-feedback">
                  <div *ngIf="f['paymentMode'].errors['required']">Payment Mode is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="chequeNo">Cheque No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="chequeNo"
                  placeholder="Enter Cheque No"
                  class="form-control"
                  formControlName="chequeNo"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['chequeNo'].errors }"
                />
                <div *ngIf="submitted && f['chequeNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['chequeNo'].errors['required']">Cheque No is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="accountNo">Account No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="accountNo"
                  placeholder="Enter account No"
                  class="form-control"
                  formControlName="accountNo"
                  [attr.disabled]="ischeque? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
                />
                <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="withdrawDate">Withdraw Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="withdrawDate"
                  class="form-control"
                  formControlName="withdrawDate"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['withdrawDate'].errors }"
                />
                <div *ngIf="submitted && f['withdrawDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['withdrawDate'].errors['required']">Withdraw Date is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="transactionNo">Transaction No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="transactionNo"
                  placeholder="Enter Transaction No"
                  class="form-control"
                  formControlName="transactionNo"
                  [attr.disabled]="isOnline ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['transactionNo'].errors }"
                />
                <div *ngIf="submitted && f['transactionNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['transactionNo'].errors['required']">Transaction No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="ddNo">DD NO <span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="ddNo"
                  placeholder="Enter ddNo"
                  class="form-control"
                  formControlName="ddNo"
                  [attr.disabled]="isDD ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['ddNo'].errors }"
                />
                <div *ngIf="submitted && f['ddNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['ddNo'].errors['required']">DD No is required</div>
                </div>
              </div>
    
              
              
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="receiptNo">Receipt No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="receiptNo"
                  placeholder="Enter Receipt No"
                  class="form-control"
                  [attr.disabled]="iscash ? true : null"
                  formControlName="receiptNo"
                  [ngClass]="{ 'is-invalid': submitted && f['receiptNo'].errors }"
                />
                <div *ngIf="submitted && f['receiptNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['receiptNo'].errors['required']">Receipt No is required</div>
                </div>
              </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="projectName">Project Name<span class="requried-field">*</span></label>
                <ng-autocomplete
              [data]="projectName"
              [searchKeyword]="keyword1"
              placeholder="Enter the Category Name"
              (selected)='selectEvent1($event)'
              (inputChanged)='onChangeSearch1($event)'
              (inputFocused)='onFocused1($event)'
              historyIdentifier="projectName"
              [itemTemplate]="itemTemplate1"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
          
            <ng-template #itemTemplate1 let-item>
              <a [innerHTML]="item.projectName"></a>
            </ng-template>
          
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
                <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                  <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="siteNo">SiteNo<span class="requried-field">*</span></label>
                <ng-autocomplete
                [data]="siteNo"
                [searchKeyword]="keyword2"
                placeholder="Enter the Category Name"
                (selected)='selectEvent2($event)'
                (inputChanged)='onChangeSearch2($event)'
                (inputFocused)='onFocused2($event)'
                historyIdentifier="siteNo"
                [itemTemplate]="itemTemplate2"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            
              <ng-template #itemTemplate2 let-item>
                <a [innerHTML]="item.siteNo"></a>
              </ng-template>
            
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
                <div *ngIf="submitted && f['siteNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['siteNo'].errors['required']">Site No is required</div>
                </div>
            </div>
            </div>
           
    
          
      
          <div class="formbutton">
    
         
            <button type="submit" class="btn btn-primary" (click)="onSubmit(expensesForm.value)">Submit</button>
            <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Salary">
        <form [formGroup]="expensesForm">
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-4">
              <label for="companyName">Company Name<span class="requried-field">*</span></label>
              <input
                type="text"
               readonly
                placeholder="Enter companyName"
                class="form-control"
                formControlName="companyName"
                [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
              />
              <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
                <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
              </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="employeeName">Employee Name<span class="requried-field">*</span></label>
              <ng-autocomplete
            [data]="employeeName"
            [searchKeyword]="keyword7"
            placeholder="Enter the  Name"
            (selected)='selectEvent7($event)'
            (inputChanged)='onChangeSearch7($event)'
            (inputFocused)='onFocused7($event)'
            historyIdentifier="employeeName"
            [itemTemplate]="itemTemplate1"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate7 let-item>
            <a [innerHTML]="item.employeeName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
             
          </div>
     
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="date">Date<span class="requried-field">*</span></label>
                <input
                
                 readonly
                  placeholder="Enter date"
                  class="form-control"
                  formControlName="date"
                  [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                />
                <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                  <div *ngIf="f['date'].errors['required'] ">Date is required</div>
                </div>
            </div>
            <!-- <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="toDate">To Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="toDate"
                  placeholder="Enter toDate"
                  class="form-control"
                  formControlName="toDate"
                  [ngClass]="{ 'is-invalid': submitted && f['toDate'].errors }"
                />
                <div *ngIf="submitted && f['toDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['toDate'].errors['required']">To Date is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="expensesType">Expenses Type<span class="requried-field">*</span></label>
                <select
            id="expensesType"
            name="expensesType"
            class="form-control"
            formControlName="expensesType"
            [ngClass]="{ 'is-invalid': submitted && f['expensesType'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of expensesType"
              [value]="data"
              >{{ data}}
            </option>
          </select>
                <div *ngIf="submitted && f['expensesType'].errors" class="invalid-feedback">
                  <div *ngIf="f['expensesType'].errors['required']">To Date is required</div>
                </div>
            </div> -->
            <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="paymentMode">Payment Mode<span class="requried-field">*</span></label>
                <select
                          id="paymentMode"
                          name="paymentMode"
                          class="form-control"
                          formControlName="paymentMode"
                          [(ngModel)]="ispayment"
                          (ngModelChange)="payment($event)"
    
                          [ngClass]="{ 'is-invalid': submitted && f['paymentMode'].errors }"
                        >
                        <option value="" selected disabled>--Please select--</option>
                          <option
                            *ngFor="let data of paymentMode"
                            [value]="data"
                            >{{ data }}
                          </option>
                        </select>
                <div *ngIf="submitted && f['paymentMode'].errors" class="invalid-feedback">
                  <div *ngIf="f['paymentMode'].errors['required']">Payment Mode is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="chequeNo">Cheque No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="chequeNo"
                  placeholder="Enter Cheque No"
                  class="form-control"
                  formControlName="chequeNo"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['chequeNo'].errors }"
                />
                <div *ngIf="submitted && f['chequeNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['chequeNo'].errors['required']">Cheque No is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="accountNo">Account No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="accountNo"
                  placeholder="Enter account No"
                  class="form-control"
                  formControlName="accountNo"
                  [attr.disabled]="ischeque? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
                />
                <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="withdrawDate">Withdraw Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="withdrawDate"
                  class="form-control"
                  formControlName="withdrawDate"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['withdrawDate'].errors }"
                />
                <div *ngIf="submitted && f['withdrawDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['withdrawDate'].errors['required']">Withdraw Date is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="transactionNo">Transaction No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="transactionNo"
                  placeholder="Enter Transaction No"
                  class="form-control"
                  formControlName="transactionNo"
                  [attr.disabled]="isOnline ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['transactionNo'].errors }"
                />
                <div *ngIf="submitted && f['transactionNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['transactionNo'].errors['required']">Transaction No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="ddNo">DD NO <span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="ddNo"
                  placeholder="Enter ddNo"
                  class="form-control"
                  formControlName="ddNo"
                  [attr.disabled]="isDD ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['ddNo'].errors }"
                />
                <div *ngIf="submitted && f['ddNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['ddNo'].errors['required']">DD No is required</div>
                </div>
              </div>
    
              
              
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="receiptNo">Receipt No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="receiptNo"
                  placeholder="Enter Receipt No"
                  class="form-control"
                  [attr.disabled]="iscash ? true : null"
                  formControlName="receiptNo"
                  [ngClass]="{ 'is-invalid': submitted && f['receiptNo'].errors }"
                />
                <div *ngIf="submitted && f['receiptNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['receiptNo'].errors['required']">Receipt No is required</div>
                </div>
              </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="projectName">Project Name<span class="requried-field">*</span></label>
                <ng-autocomplete
              [data]="projectName"
              [searchKeyword]="keyword1"
              placeholder="Enter the Category Name"
              (selected)='selectEvent1($event)'
              (inputChanged)='onChangeSearch1($event)'
              (inputFocused)='onFocused1($event)'
              historyIdentifier="projectName"
              [itemTemplate]="itemTemplate1"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
          
            <ng-template #itemTemplate1 let-item>
              <a [innerHTML]="item.projectName"></a>
            </ng-template>
          
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
                <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                  <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="siteNo">SiteNo<span class="requried-field">*</span></label>
                <ng-autocomplete
                [data]="siteNo"
                [searchKeyword]="keyword2"
                placeholder="Enter the Category Name"
                (selected)='selectEvent2($event)'
                (inputChanged)='onChangeSearch2($event)'
                (inputFocused)='onFocused2($event)'
                historyIdentifier="siteNo"
                [itemTemplate]="itemTemplate2"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            
              <ng-template #itemTemplate2 let-item>
                <a [innerHTML]="item.siteNo"></a>
              </ng-template>
            
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
                <div *ngIf="submitted && f['siteNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['siteNo'].errors['required']">Site No is required</div>
                </div>
            </div>
            </div>
           
    
          
      
          <div class="formbutton">
    
         
            <button type="submit" class="btn btn-primary" (click)="onSubmit(expensesForm.value)">Submit</button>
            <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Partners">
        <form [formGroup]="expensesForm">
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-4">
              <label for="companyName">Company Name<span class="requried-field">*</span></label>
              <input
                type="text"
               readonly
                placeholder="Enter companyName"
                class="form-control"
                formControlName="companyName"
                [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
              />
              <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
                <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
              </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="partnerName">Partner Name<span class="requried-field">*</span></label>
              <ng-autocomplete
            [data]="partnerName"
            [searchKeyword]="keyword8"
            placeholder="Enter the Name"
            (selected)='selectEvent8($event)'
            (inputChanged)='onChangeSearch8($event)'
            (inputFocused)='onFocused8($event)'
            historyIdentifier="partnerName"
            [itemTemplate]="itemTemplate8"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate8 let-item>
            <a [innerHTML]="item.partnerName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
              <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
              </div>
          </div>
     
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="date">Date<span class="requried-field">*</span></label>
                <input
                
                 readonly
                  placeholder="Enter date"
                  class="form-control"
                  formControlName="date"
                  [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                />
                <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                  <div *ngIf="f['date'].errors['required'] ">Date is required</div>
                </div>
            </div>
            <!-- <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="toDate">To Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="toDate"
                  placeholder="Enter toDate"
                  class="form-control"
                  formControlName="toDate"
                  [ngClass]="{ 'is-invalid': submitted && f['toDate'].errors }"
                />
                <div *ngIf="submitted && f['toDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['toDate'].errors['required']">To Date is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="expensesType">Expenses Type<span class="requried-field">*</span></label>
                <select
            id="expensesType"
            name="expensesType"
            class="form-control"
            formControlName="expensesType"
            [ngClass]="{ 'is-invalid': submitted && f['expensesType'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of expensesType"
              [value]="data"
              >{{ data}}
            </option>
          </select>
                <div *ngIf="submitted && f['expensesType'].errors" class="invalid-feedback">
                  <div *ngIf="f['expensesType'].errors['required']">To Date is required</div>
                </div>
            </div> -->
            <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="paymentMode">Payment Mode<span class="requried-field">*</span></label>
                <select
                          id="paymentMode"
                          name="paymentMode"
                          class="form-control"
                          formControlName="paymentMode"
                          [(ngModel)]="ispayment"
                          (ngModelChange)="payment($event)"
    
                          [ngClass]="{ 'is-invalid': submitted && f['paymentMode'].errors }"
                        >
                        <option value="" selected disabled>--Please select--</option>
                          <option
                            *ngFor="let data of paymentMode"
                            [value]="data"
                            >{{ data }}
                          </option>
                        </select>
                <div *ngIf="submitted && f['paymentMode'].errors" class="invalid-feedback">
                  <div *ngIf="f['paymentMode'].errors['required']">Payment Mode is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="chequeNo">Cheque No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="chequeNo"
                  placeholder="Enter Cheque No"
                  class="form-control"
                  formControlName="chequeNo"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['chequeNo'].errors }"
                />
                <div *ngIf="submitted && f['chequeNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['chequeNo'].errors['required']">Cheque No is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="accountNo">Account No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="accountNo"
                  placeholder="Enter account No"
                  class="form-control"
                  formControlName="accountNo"
                  [attr.disabled]="ischeque? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
                />
                <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="withdrawDate">Withdraw Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="withdrawDate"
                  class="form-control"
                  formControlName="withdrawDate"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['withdrawDate'].errors }"
                />
                <div *ngIf="submitted && f['withdrawDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['withdrawDate'].errors['required']">Withdraw Date is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="transactionNo">Transaction No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="transactionNo"
                  placeholder="Enter Transaction No"
                  class="form-control"
                  formControlName="transactionNo"
                  [attr.disabled]="isOnline ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['transactionNo'].errors }"
                />
                <div *ngIf="submitted && f['transactionNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['transactionNo'].errors['required']">Transaction No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="ddNo">DD NO <span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="ddNo"
                  placeholder="Enter ddNo"
                  class="form-control"
                  formControlName="ddNo"
                  [attr.disabled]="isDD ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['ddNo'].errors }"
                />
                <div *ngIf="submitted && f['ddNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['ddNo'].errors['required']">DD No is required</div>
                </div>
              </div>
    
              
              
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="receiptNo">Receipt No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="receiptNo"
                  placeholder="Enter Receipt No"
                  class="form-control"
                  [attr.disabled]="iscash ? true : null"
                  formControlName="receiptNo"
                  [ngClass]="{ 'is-invalid': submitted && f['receiptNo'].errors }"
                />
                <div *ngIf="submitted && f['receiptNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['receiptNo'].errors['required']">Receipt No is required</div>
                </div>
              </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="projectName">Project Name<span class="requried-field">*</span></label>
                <ng-autocomplete
              [data]="projectName"
              [searchKeyword]="keyword1"
              placeholder="Enter the Category Name"
              (selected)='selectEvent1($event)'
              (inputChanged)='onChangeSearch1($event)'
              (inputFocused)='onFocused1($event)'
              historyIdentifier="projectName"
              [itemTemplate]="itemTemplate1"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
          
            <ng-template #itemTemplate1 let-item>
              <a [innerHTML]="item.projectName"></a>
            </ng-template>
          
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
                <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                  <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="siteNo">SiteNo<span class="requried-field">*</span></label>
                <ng-autocomplete
                [data]="siteNo"
                [searchKeyword]="keyword2"
                placeholder="Enter the Category Name"
                (selected)='selectEvent2($event)'
                (inputChanged)='onChangeSearch2($event)'
                (inputFocused)='onFocused2($event)'
                historyIdentifier="siteNo"
                [itemTemplate]="itemTemplate2"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            
              <ng-template #itemTemplate2 let-item>
                <a [innerHTML]="item.siteNo"></a>
              </ng-template>
            
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
                <div *ngIf="submitted && f['siteNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['siteNo'].errors['required']">Site No is required</div>
                </div>
            </div>
            </div>
           
    
          
      
          <div class="formbutton">
    
         
            <button type="submit" class="btn btn-primary" (click)="onSubmit(expensesForm.value)">Submit</button>
            <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Others">
        <form [formGroup]="expensesForm">
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-4">
              <label for="companyName">Company Name<span class="requried-field">*</span></label>
              <input
                type="text"
               readonly
                placeholder="Enter companyName"
                class="form-control"
                formControlName="companyName"
                [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
              />
              <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
                <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
              </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
              <label for="expensesType">Expenses Type<span class="requried-field">*</span></label>
              <ng-autocomplete
            [data]="expensesType"
            [searchKeyword]="keyword9"
            placeholder="Enter the Name"
            (selected)='selectEvent9($event)'
            (inputChanged)='onChangeSearch9($event)'
            (inputFocused)='onFocused9($event)'
            historyIdentifier="expensesType"
            [itemTemplate]="itemTemplate9"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate9 let-item>
            <a [innerHTML]="item.expensesType"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
              <!-- <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
              </div> -->
          </div>
     
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="date">Date<span class="requried-field">*</span></label>
                <input
                
                 readonly
                  placeholder="Enter date"
                  class="form-control"
                  formControlName="date"
                  [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                />
                <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                  <div *ngIf="f['date'].errors['required'] ">Date is required</div>
                </div>
            </div>
            <!-- <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="toDate">To Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="toDate"
                  placeholder="Enter toDate"
                  class="form-control"
                  formControlName="toDate"
                  [ngClass]="{ 'is-invalid': submitted && f['toDate'].errors }"
                />
                <div *ngIf="submitted && f['toDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['toDate'].errors['required']">To Date is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="expensesType">Expenses Type<span class="requried-field">*</span></label>
                <select
            id="expensesType"
            name="expensesType"
            class="form-control"
            formControlName="expensesType"
            [ngClass]="{ 'is-invalid': submitted && f['expensesType'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of expensesType"
              [value]="data"
              >{{ data}}
            </option>
          </select>
                <div *ngIf="submitted && f['expensesType'].errors" class="invalid-feedback">
                  <div *ngIf="f['expensesType'].errors['required']">To Date is required</div>
                </div>
            </div> -->
            <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="paymentMode">Payment Mode<span class="requried-field">*</span></label>
                <select
                          id="paymentMode"
                          name="paymentMode"
                          class="form-control"
                          formControlName="paymentMode"
                          [(ngModel)]="ispayment"
                          (ngModelChange)="payment($event)"
    
                          [ngClass]="{ 'is-invalid': submitted && f['paymentMode'].errors }"
                        >
                        <option value="" selected disabled>--Please select--</option>
                          <option
                            *ngFor="let data of paymentMode"
                            [value]="data"
                            >{{ data }}
                          </option>
                        </select>
                <div *ngIf="submitted && f['paymentMode'].errors" class="invalid-feedback">
                  <div *ngIf="f['paymentMode'].errors['required']">Payment Mode is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="chequeNo">Cheque No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="chequeNo"
                  placeholder="Enter Cheque No"
                  class="form-control"
                  formControlName="chequeNo"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['chequeNo'].errors }"
                />
                <div *ngIf="submitted && f['chequeNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['chequeNo'].errors['required']">Cheque No is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="accountNo">Account No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="accountNo"
                  placeholder="Enter account No"
                  class="form-control"
                  formControlName="accountNo"
                  [attr.disabled]="ischeque? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
                />
                <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="withdrawDate">Withdraw Date<span class="requried-field">*</span></label>
                <input
                  type="date"
                  id="withdrawDate"
                  class="form-control"
                  formControlName="withdrawDate"
                  [attr.disabled]="ischeque ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['withdrawDate'].errors }"
                />
                <div *ngIf="submitted && f['withdrawDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['withdrawDate'].errors['required']">Withdraw Date is required</div>
                </div>
              </div>
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="transactionNo">Transaction No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="transactionNo"
                  placeholder="Enter Transaction No"
                  class="form-control"
                  formControlName="transactionNo"
                  [attr.disabled]="isOnline ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['transactionNo'].errors }"
                />
                <div *ngIf="submitted && f['transactionNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['transactionNo'].errors['required']">Transaction No is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="ddNo">DD NO <span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="ddNo"
                  placeholder="Enter ddNo"
                  class="form-control"
                  formControlName="ddNo"
                  [attr.disabled]="isDD ? true : null"
                  [ngClass]="{ 'is-invalid': submitted && f['ddNo'].errors }"
                />
                <div *ngIf="submitted && f['ddNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['ddNo'].errors['required']">DD No is required</div>
                </div>
              </div>
    
              
              
              
              <div class="form-group col-sm-12 col-md-6 col-lg-4">
                <label for="receiptNo">Receipt No<span class="requried-field">*</span></label>
                <input
                  type="text"
                  id="receiptNo"
                  placeholder="Enter Receipt No"
                  class="form-control"
                  [attr.disabled]="iscash ? true : null"
                  formControlName="receiptNo"
                  [ngClass]="{ 'is-invalid': submitted && f['receiptNo'].errors }"
                />
                <div *ngIf="submitted && f['receiptNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['receiptNo'].errors['required']">Receipt No is required</div>
                </div>
              </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="projectName">Project Name<span class="requried-field">*</span></label>
                <ng-autocomplete
              [data]="projectName"
              [searchKeyword]="keyword1"
              placeholder="Enter the Category Name"
              (selected)='selectEvent1($event)'
              (inputChanged)='onChangeSearch1($event)'
              (inputFocused)='onFocused1($event)'
              historyIdentifier="projectName"
              [itemTemplate]="itemTemplate1"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
          
            <ng-template #itemTemplate1 let-item>
              <a [innerHTML]="item.projectName"></a>
            </ng-template>
          
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
                <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
                  <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                <label for="siteNo">SiteNo<span class="requried-field">*</span></label>
                <ng-autocomplete
                [data]="siteNo"
                [searchKeyword]="keyword2"
                placeholder="Enter the Category Name"
                (selected)='selectEvent2($event)'
                (inputChanged)='onChangeSearch2($event)'
                (inputFocused)='onFocused2($event)'
                historyIdentifier="siteNo"
                [itemTemplate]="itemTemplate2"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            
              <ng-template #itemTemplate2 let-item>
                <a [innerHTML]="item.siteNo"></a>
              </ng-template>
            
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
                <div *ngIf="submitted && f['siteNo'].errors" class="invalid-feedback">
                  <div *ngIf="f['siteNo'].errors['required']">Site No is required</div>
                </div>
            </div>
            </div>
           
    
          
      
          <div class="formbutton">
    
         
            <button type="submit" class="btn btn-primary" (click)="onSubmit(expensesForm.value)">Submit</button>
            <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
   
  </div>
  