import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, mapTo, throwError, } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    expensesUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/expenses.json`;
    siteUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/site.json`;
    supplierUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/supplier.json`;
    labourUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/labour.json`;
    employeeUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/employee.json`;
    partnerUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/partner.json`;
    //siteUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/site.json`;

    constructor(private http:HttpClient) {}

    create(data: any) {
        return this.http.post(`${this.expensesUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      get(): Observable<any> {
        return this.http.get(this.expensesUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getSupplier(): Observable<any> {
        return this.http.get(this.supplierUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getLabour(): Observable<any> {
        return this.http.get(this.labourUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getEmployee(): Observable<any> {
        return this.http.get(this.employeeUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getPartner(): Observable<any> {
        return this.http.get(this.partnerUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getExpenses(): Observable<any> {
        return this.http.get(this.expensesUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getSite(): Observable<any> {
        return this.http.get(this.siteUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
}