import { Component } from '@angular/core';
import { DataService } from '../dataService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notificationService';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  paymentMode:string[]=['cash','checque','DD','online']
  siteStatusData: string[] = ['On-going','Started','Completed','Stopped',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  expensesForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  expensesType:any[]=[];
  paymentMethod:any[]=[];
  projectName:any[]=[];
  ispayment:boolean = false;
  isEnabled:boolean = false;
  isDisabled = false;
  keyword1='projectName';
  keyword2='siteNo';
  keyword5='supplierName';
  keyword6='labourName';
  keyword7='employeeName';
  keyword8='partnerName';
  keyword9='expensesType'
  siteNo:any[]=[];
  siteNoData:any[]=[];
  iscredit:boolean=false;
  isdebit:boolean=false;
  iscash:boolean=false;
  ischeque:boolean=false;
  isDD:boolean=false;
  isOnline:boolean = false;
  supplierName:any[]=[];
  labourName:any[]=[];
  employeeName:any[]=[];
  expenseType:any[]=[];
  partnerName:any[]=[];

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router
  ) {}

  ngOnInit()  {
    this.expensesForm= this.formBuilder.group({
      companyName: ['Aura Contrivers'],
      supplierName:[''],
      labourName:[''],
      employeeName:[''],
      partnerName:[''],
      expensesType:[''],
      date: [''],
      receiptNo: ['', Validators.required],
      chequeNo: ['', Validators.required],
      accountNo:['', Validators.required],
      withdrawDate:['', Validators.required],
      transactionNo:['', Validators.required],
      ddNo:['', Validators.required],
      description:['', Validators.required],
      paymentMode: ['', Validators.required],
      projectName: ['', Validators.required],
      siteNo: ['', Validators.required],
      //ustatus: ['',Validators.required]
     
    });

     this.expensesForm.patchValue({
      date:moment().format('DD-MM-YYYY')
     })
  }

 

  goBack() {
    this.router.navigate(['/dashboard/expenses']);
  }
 
  get f() {
    return this.expensesForm.controls;
  }

  onSubmit(data: any) {
    data.postOn=moment().format()
    this.data.create(data).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/expenses'])
    })
  }
  onCancel(){
    this.expensesForm.reset()
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
   
    this.expensesForm.patchValue({
      projectName:item.projectName
    })
    this.data.getSite().subscribe((res:any)=>{
      this.siteNoData = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        this.siteNoData.forEach((val:any)=>{
         if(val.projectName == item.projectName){
          this.siteNo.push(val)
         }
        })
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getSite().subscribe((res:any)=>{
      this.projectName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        return this.projectName.filter( val  =>   val.toLowerCase().includes(search));
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }
  selectEvent2(item:any) {
    // do something with selected item
    console.log(item)
   
    this.expensesForm.patchValue({
      siteNo:item.siteNo
    })
  }
 
  onChangeSearch2(search: string) {
    // fetch remote data from here
    console.log(search)
    return this.siteNo.find((val:any)=>{
      val.siteNo.includes(search)
      console.log(val,"siteNo")
    })
  }

  onFocused2(e:any) {
    console.log(e)
    // do something
  }
  selectEvent5(item:any) {
    // do something with selected item
    console.log(item)
   
    this.expensesForm.patchValue({
      supplierName:item.supplierName
    })
  }
 
  onChangeSearch5(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getSupplier().subscribe((res:any)=>{
      this.supplierName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        return this.supplierName.filter( val  =>   val.toLowerCase().includes(search));
    })
    
  }

  onFocused5(e:any) {
    console.log(e)
    // do something
  }
  selectEvent6(item:any) {
    // do something with selected item
    console.log(item)
   
    this.expensesForm.patchValue({
      labourName:item.labourName
    })
  }
 
  onChangeSearch6(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getLabour().subscribe((res:any)=>{
      this.labourName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        return this.labourName.filter( val  =>   val.toLowerCase().includes(search));
    })
  }

  onFocused6(e:any) {
    console.log(e)
    // do something
  }
  selectEvent7(item:any) {
    // do something with selected item
    console.log(item)
   
    this.expensesForm.patchValue({
      employeeName:item.employeeName
    })
  }
 
  onChangeSearch7(search: string) {
    // fetch remote data from here
    console.log(search)
   search.toLowerCase();
   this.data.getEmployee().subscribe((res:any)=>{
    this.employeeName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
        console.log(this.projectName)
      return this.employeeName.filter( val  =>   val.toLowerCase().includes(search));
  })
  }

  onFocused7(e:any) {
    console.log(e)
    // do something
  }
  selectEvent8(item:any) {
    // do something with selected item
    console.log(item)
   
    this.expensesForm.patchValue({
      partnerName:item.partnerName
    })
  }
 
  onChangeSearch8(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getPartner().subscribe((res:any)=>{
      this.partnerName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        return this.partnerName.filter( val  =>   val.toLowerCase().includes(search));
    })
  }

  onFocused8(e:any) {
    console.log(e)
    // do something
  }
  selectEvent9(item:any) {
    // do something with selected item
    console.log(item)
   
    this.expensesForm.patchValue({
      expensesType:item.expensesType
    })
  }
 
  onChangeSearch9(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getExpenses().subscribe((res:any)=>{
      this.expenseType = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        return this.expenseType.filter( val  =>   val.toLowerCase().includes(search));
    })
  }

  onFocused9(e:any) {
    console.log(e)
    // do something
  }
  payment(data:any){
    console.log(data)
    if(data == "cash"){
      console.log("deb")
      this.iscash = false;
      this.ischeque=true;
      this.isDD = true;
      this.isOnline= true;

    } else if(data == "checque"){
      this.iscash = true;
      this.ischeque=false;
      this.isDD = true;
      this.isOnline= true;
      console.log("crd")
    } else if(data == "DD"){
      this.iscash = true;
      this.ischeque=true;
      this.isDD = false;
      this.isOnline= true;
    } else if(data == "online"){
      this.iscash = true;
      this.ischeque=true;
      this.isDD = true;
      this.isOnline= false;
    }
    }
}
