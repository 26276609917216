import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './dataService';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrl: './expenses.component.scss'
})
export class ExpensesComponent implements OnInit{
  expensesList:any[]=[];
  defaultPagination: string | undefined = undefined;
  page: number = 1;
  _itemsPerPage: any = "25";
  isChecked: boolean | undefined = false;
  checkedIdList: any[] = [];
  id: string = "";
  selectedId: string[] = [];
  constructor(private router:Router,
    private data:DataService
  ) { }

  ngOnInit() {
    this.data.get().subscribe((val:any)=>{
      if (val) {
        this.expensesList = Object.keys(val).map(key => ({ id: key, ...val[key] }));
        console.log(this.expensesList)
      }
      })
  }

  add(){
    this.router.navigate(['/dashboard/expenses/add']);
  }
  onDelete(_id:string) {
   
  }

  onEdit(_id:string) {
    this.router.navigate(['/dashboard/expenses/edit']);
  }

  getSelectedId(isCheckedStatus: boolean, id: string) {
    if (isCheckedStatus === true) {
      this.id = id;
      this.isChecked = isCheckedStatus;
      this.checkedIdList.push(this.id);
    } else {
      this.isChecked = undefined;
      this.id = "";
      const index = this.checkedIdList.indexOf(this.id);
      this.checkedIdList.splice(index);
    }
  }
}
